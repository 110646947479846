<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>内容管理</a-breadcrumb-item>
    <a-breadcrumb-item>片库</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <a-row class="tool-row">
      <a-col :span="6">
        <a-row>
          <!-- 剧名： -->
          <a-col :span="6" class="tool-title">剧名：</a-col>
          <a-col :span="18">
            <a-input v-model:value="nameModel" class="tool-item" placeholder="请输入" />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 供应商： -->
          <a-col :span="6" class="tool-title">供应商：</a-col>
          <a-col :span="18">
            <a-select v-model:value="supplierID" class="tool-item" placeholder="请选择">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in supplierList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 评级： -->
          <a-col :span="6" class="tool-title">评级：</a-col>
          <a-col :span="18">
            <a-select v-model:value="gradeID" class="tool-item" placeholder="请选择">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in Grades" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 版权： -->
          <a-col :span="6" class="tool-title">版权：</a-col>
          <a-col :span="18">
            <a-select v-model:value="copyrightID" class="tool-item" placeholder="请选择">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in Copyrights" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 剧情分类： -->
          <a-col :span="6" class="tool-title">剧情分类：</a-col>
          <a-col :span="18">
            <a-select v-model:value="plotType" class="tool-item" placeholder="请选择">
              <a-select-option v-for="item in PlotTypes" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 操作 -->
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap;">
            <a-button type="primary" @click="touchSearch">查询</a-button>
            <a-button style="margin-left: 16px" @click="touchReset">重置</a-button>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 操作 -->
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap; text-align: right;">
            <a-button type="primary" @click="touchAdd"><plus-outlined /> 新建</a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <!-- 多选组件 -->
  <TableCheckbox
    sourceKey="id"
    :dataSource="dataSource"
    :selectedRowKeys="selectedRowKeys"
    :selectedRows="selectedRows"
    :pagination="pagination"
    :isLoading="isLoading"
  >
    <!-- 按钮文案 -->
    <template #checkbox>
      <!-- 分发 -->
      <a-button style="margin-left: 10px;" @click="touchBatch(1)">分发</a-button>
      <!-- 上架 -->
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="item in Platforms" :key="item.value" @click="touchBatch(2, 1, item)">{{ item.text }}</a-menu-item>
          </a-menu>
        </template>
        <a-button style="margin-left: 10px;">上架 <DownOutlined /></a-button>
      </a-dropdown>
      <!-- 下架 -->
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="item in Platforms" :key="item.value" @click="touchBatch(2, 0, item)">{{ item.text }}</a-menu-item>
          </a-menu>
        </template>
        <a-button style="margin-left: 10px;">下架 <DownOutlined /></a-button>
      </a-dropdown>
      <!-- 首页展示 -->
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="item in Platforms" :key="item.value" @click="touchBatch(3, 1, item)">{{ item.text }}</a-menu-item>
          </a-menu>
        </template>
        <a-button style="margin-left: 10px;">首页展示 <DownOutlined /></a-button>
      </a-dropdown>
      <!-- 首页隐藏 -->
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="item in Platforms" :key="item.value" @click="touchBatch(3, 0, item)">{{ item.text }}</a-menu-item>
          </a-menu>
        </template>
        <a-button style="margin-left: 10px;">首页隐藏 <DownOutlined /></a-button>
      </a-dropdown>
    </template>
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      :scroll="{ x: scrollX, y: 'max-content' }"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 剧名 -->
        <template v-if="column.key === 'remark_name'">
          <img class="cover-img" :src="$pub.CDN_URL(record.cover && record.cover[0] && record.cover[0].url)">
          <span class="cover-title">{{ record[column.key] }}</span>
        </template>
        <!-- 片源 -->
        <template v-if="column.key === 'film_type'">
          {{ (FilmTypes.find(item => item.value === record[column.key]) || {}).text || '-' }}
        </template>
        <!-- 版权期限 -->
        <template v-if="column.key === 'copyright_date'">
          {{ record[column.key] == 1 ? '永久' : record[column.key] }}
        </template>
        <!-- 抖小剧目ID -->
        <template v-if="column.key === 'douyin_drama_id'">
          <a-tooltip v-if="record.douyin_drama_id">
            <template #title>{{ record.douyin_drama_id }}</template>
            <info-circle-outlined />
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <!-- 抖小状态 -->
        <template v-if="column.key === 'audit_status'">
          {{ (AuditStatusTt.find(item => item.value === record[column.key]) || {}).text || '-' }}
          <a-tooltip v-if="record.audit_msg">
            <template #title>{{ record.audit_msg }}</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <!-- 微小状态 -->
        <template v-if="column.key === 'wx_audit_status'">
          {{ (AuditStatusTt.find(item => item.value === record[column.key]) || {}).text || '-' }}
          <a-tooltip v-if="record.wx_audit_msg">
            <template #title>{{ record.wx_audit_msg }}</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <!-- 快小剧目ID -->
        <template v-if="column.key === 'ks_drama_id'">
          <a-tooltip v-if="record.ks_drama_id">
            <template #title>{{ record.ks_drama_id }}</template>
            <info-circle-outlined />
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <!-- 快小状态 -->
        <template v-if="column.key === 'ks_audit_status'">
          {{ (AuditStatusTt.find(item => item.value === record[column.key]) || {}).text || '-' }}
          <a-tooltip v-if="record.ks_audit_msg">
            <template #title>{{ record.ks_audit_msg }}</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <!-- 独家 -->
        <template v-if="column.key === 'is_unique_auth'">
          {{ record[column.key] == 1 ? '独家' : '非独家' }}
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'operation'">
          <a class="operation-item" @click="touchInfo(record)">基本信息</a>
          <a class="operation-item" @click="touchSeries(record)">剧集</a>
          <a class="operation-item" @click="touchDistributeRecord(record)">分发记录</a>
          <a class="operation-item" @click="touchStatus(record)">状态设置</a>
          <!-- 抖小操作 -->
          <a-dropdown>
            <a class="operation-item" @click.prevent>
              抖小操作 <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="touchTtCreate(record)" v-if="!record.douyin_drama_id">创建短剧</a-menu-item>
                <a-menu-item @click="touchTtEdit(record)" v-else>编辑信息</a-menu-item>
                <a-menu-item @click="touchTtBind(record)">绑定剧集</a-menu-item>
                <a-menu-item @click="touchTtReview(record)">短剧送审</a-menu-item>
                <a-menu-item @click="touchTtLaunch(record)">剧目上线</a-menu-item>
                <a-menu-item @click="touchTtAuth(record)">授权&绑定页面</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <!-- 微小操作 -->
          <a-dropdown>
            <a class="operation-item" @click.prevent>
              微小操作 <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="touchWxEdit(record)">编辑信息</a-menu-item>
                <a-menu-item @click="touchWxAuth(record)">微小授权</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <!-- 快小操作 -->
          <a-dropdown>
            <a class="operation-item" @click.prevent>
              快小操作 <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="touchKsCreate(record)" v-if="!record.ks_drama_id">创建短剧</a-menu-item>
                <a-menu-item @click="touchKsEdit(record)" v-else>编辑信息</a-menu-item>
                <a-menu-item @click="touchKsReview(record)">短剧送审</a-menu-item>
                <a-menu-item @click="touchKsRefresh(record)">刷新审核状态</a-menu-item>
                <a-menu-item @click="touchKsLaunch(record)">剧目上线</a-menu-item>
                <a-menu-item @click="touchKsAuth(record)">授权小程序</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </template>
    </a-table>
  </TableCheckbox>
  <!-- 新建 -->
  <NewFilmDrawer ref="RefNewFilmDrawer" @success="getDramaList"></NewFilmDrawer>
  <!-- 分发 -->
  <DistributeDrawer ref="RefDistributeDrawer" @success="getDramaList"></DistributeDrawer>
  <!-- 分发记录 -->
  <DistributeRecordsDrawer ref="RefDistributeRecordsDrawer"></DistributeRecordsDrawer>
  <!-- 剧集 -->
  <SeriesDrawer ref="RefSeriesDrawer"></SeriesDrawer>
  <!-- 状态设置 -->
  <StatusDrawer ref="RefStatusDrawer"></StatusDrawer>
  <!-- 创建短剧 -->
  <TtNewDramaDrawer ref="RefTtNewDramaDrawer" @success="getDramaList"></TtNewDramaDrawer>
  <WxNewDramaDrawer ref="RefWxNewDramaDrawer" @success="getDramaList"></WxNewDramaDrawer>
  <KsNewDramaDrawer ref="RefKsNewDramaDrawer" @success="getDramaList"></KsNewDramaDrawer>
  <!-- 授权&绑定页面 -->
  <TtAuthDramaDrawer ref="RefTtAuthDramaDrawer"></TtAuthDramaDrawer>
  <KsAuthDramaDrawer ref="RefKsAuthDramaDrawer"></KsAuthDramaDrawer>
  <!-- 微小授权 -->
  <WxAuthDramaDrawer ref="RefWxAuthDramaDrawer"></WxAuthDramaDrawer>
</template>

<script setup>
import { onBeforeMount, reactive, ref } from 'vue'
import { Grades, Copyrights, FilmTypes, AuditStatusTt, Platforms, PlotTypes } from '@/utils/constantList'
import TableCheckbox from '@/components/TableCheckbox'
import NewFilmDrawer from './components-film-library/NewFilmDrawer'
import DistributeDrawer from './components-film-library/DistributeDrawer'
import DistributeRecordsDrawer from './components-film-library/DistributeRecordsDrawer'
import SeriesDrawer from './components-film-library/SeriesDrawer'
import StatusDrawer from './components-film-library/StatusDrawer.vue'
import TtNewDramaDrawer from './components-film-library/TtNewDramaDrawer.vue'
import TtAuthDramaDrawer from './components-film-library/TtAuthDramaDrawer.vue'
import WxNewDramaDrawer from './components-film-library/WxNewDramaDrawer.vue'
import KsNewDramaDrawer from './components-film-library/KsNewDramaDrawer.vue'
import KsAuthDramaDrawer from './components-film-library/KsAuthDramaDrawer.vue'
import WxAuthDramaDrawer from './components-film-library/WxAuthDramaDrawer.vue'
import { message, Modal } from 'ant-design-vue'
import { dramaList, supplierAll, dySyncEdit, dyExamineDrama, dyOnlineDrama, dramaSetStatus, ksExamine, ksRefresh, ksOnline } from '@/api/copyright'
// 引入 dayjs 及 扩展函数
import dayjs from 'dayjs'

// 新增
let RefNewFilmDrawer = ref(null)
let RefWxNewDramaDrawer = ref(null)
let RefKsNewDramaDrawer = ref(null)
// 微小授权
let RefWxAuthDramaDrawer = ref(null)
// 分发
let RefDistributeDrawer = ref(null)
// 分发记录
let RefDistributeRecordsDrawer = ref(null)
// 创建短剧
let RefTtNewDramaDrawer = ref(null)
// 授权&绑定页面
let RefTtAuthDramaDrawer = ref(null)
let RefKsAuthDramaDrawer = ref(null)
// 剧集
let RefSeriesDrawer = ref(null)
// 状态设置
let RefStatusDrawer = ref(null)
// 剧名
let nameModel = ref(undefined)
// 剧名
let name = ref(undefined)
// 供应商
let supplierID = ref(undefined)
let supplierList = ref([])
// 评级
let gradeID = ref(undefined)
// 剧情分类
let plotType = ref(undefined)
// 版权
let copyrightID = ref(undefined)
// 加载
let isLoading = ref(false)
// 选中
let selectedRowKeys = ref([])
let selectedRows = ref([])
// 数据源
let dataSource = ref([])
// 滚动范围
let scrollX = ref(0)
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '剧名',
    dataIndex: 'remark_name',
    key: 'remark_name',
    fixed: 'left',
    width: 300
  },
  {
    title: '剧集ID',
    dataIndex: 'id',
    key: 'id',
    width: 100
  },
  {
    title: '评级',
    dataIndex: 'grade',
    key: 'grade',
    width: 100
  },
  {
    title: '总集数',
    dataIndex: 'total_ep',
    key: 'total_ep',
    width: 100
  },
  {
    title: '片源',
    dataIndex: 'film_type',
    key: 'film_type',
    width: 100
  },
  {
    title: '版权期限',
    dataIndex: 'copyright_date',
    key: 'copyright_date',
    width: 100
  },
  {
    title: '抖小剧目ID',
    dataIndex: 'douyin_drama_id',
    key: 'douyin_drama_id',
    width: 150
  },
  {
    title: '抖小状态',
    dataIndex: 'audit_status',
    key: 'audit_status',
    width: 100
  },
  {
    title: '抖小能力',
    dataIndex: 'scope_list',
    key: 'scope_list',
    width: 200
  },
  {
    title: '微小状态',
    dataIndex: 'wx_audit_status',
    key: 'wx_audit_status',
    width: 100
  },
  {
    title: '快小剧目ID',
    dataIndex: 'ks_drama_id',
    key: 'ks_drama_id',
    width: 150
  },
  {
    title: '快小状态',
    dataIndex: 'ks_audit_status',
    key: 'ks_audit_status',
    width: 100
  },
  // {
  //   title: '当前渠道',
  //   dataIndex: 'num',
  //   key: 'num',
  //   width: 100
  // },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    fixed: 'right',
    width: 275
  }
])

onBeforeMount (() => {
  // 初始化获取
  touchSearch()
  // 获取供应商列表
  getSupplierAll()
  // 获取滚动宽度
  getScrollX()
})

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  // 获取列表
  getDramaList()
}

// 多选处理
function onSelectChange (rowKeys, rows) {
  selectedRowKeys.value = rowKeys
  selectedRows.value = rows
}

// 查询
function touchSearch () {
  // 调整参数
  name.value = nameModel.value
  pagination.current = 1
  // 获取列表
  getDramaList()
}

// 重置
function touchReset () {
  nameModel.value = undefined
  name.value = undefined
  supplierID.value = undefined
  gradeID.value = undefined
  copyrightID.value = undefined
  plotType.value = undefined
  // 初始化获取
  touchSearch()
}

// 滚动X
function getScrollX () {
  var x = 0
  columns.value.forEach(item => {
    x += item.width
  })
}

// 新建
function touchAdd () {
  RefNewFilmDrawer.value.showDrawer()
}

// 创建短剧
function touchTtCreate(record) {
  // Modal.error({
  //   title: '无抖小封面ID',
  //   content: '请先在基本信息中将封面同步至抖小'
  // })
  RefTtNewDramaDrawer.value.showDrawer(record)
}

// 创建短剧
function touchKsCreate(record) {
  // Modal.error({
  //   title: '无抖小封面ID',
  //   content: '请先在基本信息中将封面同步至抖小'
  // })
  RefKsNewDramaDrawer.value.showDrawer(record)
}

// 编辑信息
function touchTtEdit(record) {
  // Modal.error({
  //   title: '尚未创建短剧',
  //   content: '请先创建抖小短剧'
  // })
  // Modal.error({
  //   title: '有剧集未同步至抖小',
  //   content: '请先在剧集中将所有剧集同步至抖小'
  // })
  RefTtNewDramaDrawer.value.showDrawer(record)
}

// 编辑信息
function touchWxEdit (record) {
  RefWxNewDramaDrawer.value.showDrawer(record)
}

// 微小授权
function touchWxAuth (record) {
  RefWxAuthDramaDrawer.value.showDrawer(record)
}

// 编辑信息
function touchKsEdit (record) {
  RefKsNewDramaDrawer.value.showDrawer(record)
}

// 刷新审核状态
function touchKsRefresh (record) {
  isLoading.value = true
  ksRefresh({ ks_drama_id: record.ks_drama_id }).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0 && (data ? data.error_msg === 'success' : true)) {
      message.success('刷新成功')
      getDramaList()
    } else {
      Modal.error({
        title: '操作错误',
        content: (data && data.error_msg) || res.message
      })
    }
  }).catch(() => {
    isLoading.value = false
    Modal.error({
      title: '操作错误',
      content: '刷新失败'
    })
  })
}

// 绑定剧集
function touchTtBind(record) {
  isLoading.value = true
  const params = {
    type: 2,
    id: record.id
  }
  dySyncEdit(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0 && (data ? data.err_no === 0 : true)) {
      message.success('绑定成功')
      getDramaList()
    } else {
      Modal.error({
        title: '操作错误',
        content: (data && data.err_msg) || res.message
      })
    }
  }).catch(() => {
    isLoading.value = false
    Modal.error({
      title: '操作错误',
      content: '绑定失败'
    })
  })
}

// 短剧送审
function touchTtReview(record) {
  // Modal.error({
  //   title: '尚未绑定剧集',
  //   content: '请先将剧集跟短剧绑定'
  // })
  isLoading.value = true
  const params = {
    drama_id: record.id
  }
  dyExamineDrama(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0 && (data ? data.err_no === 0 : true)) {
      message.success('送审成功')
      getDramaList()
    } else {
      Modal.error({
        title: '操作错误',
        content: (data && data.err_msg) || res.message
      })
    }
  }).catch(() => {
    isLoading.value = false
    Modal.error({
      title: '操作错误',
      content: '送审失败'
    })
  })
}

// 短剧送审
function touchKsReview(record) {
  // Modal.error({
  //   title: '尚未绑定剧集',
  //   content: '请先将剧集跟短剧绑定'
  // })
  isLoading.value = true
  const params = {
    ks_drama_id: record.ks_drama_id
  }
  ksExamine(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0 && (data ? data.error_msg === 'success' : true)) {
      message.success('送审成功')
      getDramaList()
    } else {
      Modal.error({
        title: '操作错误',
        content: (data && data.error_msg) || res.message
      })
    }
  }).catch(() => {
    isLoading.value = false
    Modal.error({
      title: '操作错误',
      content: '送审失败'
    })
  })
}

// 剧目上线
function touchTtLaunch(record) {
  // Modal.error({
  //   title: '目前无法上线',
  //   content: '审核通过的剧目才可上线'
  // })
  isLoading.value = true
  const params = {
    drama_id: record.id
  }
  dyOnlineDrama(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0 && (data ? data.err_no === 0 : true)) {
      message.success('上线成功')
      getDramaList()
    } else {
      Modal.error({
        title: '操作错误',
        content: (data && data.err_msg) || res.message
      })
    }
  }).catch(() => {
    isLoading.value = false
    Modal.error({
      title: '操作错误',
      content: '上线失败'
    })
  })
}

// 剧目上线
function touchKsLaunch(record) {
  isLoading.value = true
  ksOnline({ ks_drama_id: record.ks_drama_id }).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0 && (data ? data.error_msg === 'success' : true)) {
      message.success('上线成功')
      getDramaList()
    } else {
      Modal.error({
        title: '操作错误',
        content: (data && data.error_msg) || res.message
      })
    }
  }).catch(() => {
    isLoading.value = false
    Modal.error({
      title: '操作错误',
      content: '上线失败'
    })
  })
}

// 授权&绑定页面
function touchTtAuth(record) {
  RefTtAuthDramaDrawer.value.showDrawer(record)
}

// 授权&绑定页面
function touchKsAuth(record) {
  RefKsAuthDramaDrawer.value.showDrawer(record)
}

// 批量操作
function touchBatch (type, status, platform) {
  if (selectedRowKeys.value.length) {
    // 已过版权期限的短剧不可勾选
    const limit = selectedRows.value.some(item => {
      // 非永久期限 && 当前日期在版权结束日期之后
      if (item.copyright_date != 1 && dayjs().isAfter(item.copyright_date, 'day')) {
        return true
      } else {
        return false
      }
    })
    if (limit) {
      return message.error('不可分发已过期版权期限的短剧')
    }
    if (type === 1) {
      // 分发
      RefDistributeDrawer.value.showDrawer({
        // 当前勾选的所有id
        keys: selectedRowKeys.value,
        // 传入固定的【独家】选项id，不允许组件内修改（场景：若勾选中含有非独家，则不允许组件内修改为独家）
        fixedExclusive: selectedRows.value.some(item => item.is_unique_auth === 0) ? 0 : undefined
      })
    } else if (type === 2) {
      // 上架
      Modal.confirm({
        title: '提示',
        content: status ? `确定要上架${platform.text}平台？` : `确定要下架${platform.text}平台？`,
        cancelText: '取 消',
        okText: '确 定',
        onOk: () => {
          changeStatus(status, platform.value, 'status')
        }
      })
    } else if (type === 3) {
      // 首页展示
      Modal.confirm({
        title: '提示',
        content: status ? `确定要在${platform.text}平台首页展示？` : `确定要在${platform.text}平台首页隐藏？`,
        cancelText: '取 消',
        okText: '确 定',
        onOk: () => {
          changeStatus(status, platform.value, 'home_status')
        }
      })
    }
  } else {
    message.error('请选择影剧')
  }
}

// 切换状态
function changeStatus (status, platform_id, key) {
  isLoading.value = true
  const params = {
    drama_ids: selectedRowKeys.value,
    platform_id: platform_id,
    type: key,
    status: status ? 1 : 0
  }
  dramaSetStatus(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      message.success('设置成功')
      // 清空选中
      selectedRowKeys.value = []
      selectedRows.value = []
      // 重新获取
      getDramaList()
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
    message.error('设置失败')
  })
}

// 剧集
function touchSeries (record) {
  RefSeriesDrawer.value.showDrawer(record)
}

// 状态设置
function touchStatus(record) {
  RefStatusDrawer.value.showDrawer(record)
}

// 分发记录
function touchDistributeRecord (record) {
  RefDistributeRecordsDrawer.value.showDrawer(record)
}

// 基本信息
function touchInfo (record) {
  RefNewFilmDrawer.value.showDrawer(record)
}

// 获取供应商列表
function getSupplierAll () {
  // 展示全部供应商
  supplierAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      supplierList.value = data
    }
  })
}

// 获取列表
function getDramaList () {
  isLoading.value = true
  const params = {
    name: name.value,
    supplier_id: supplierID.value,
    grade: gradeID.value,
    male_and_female: plotType.value,
    copyright_status: copyrightID.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  dramaList(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
      // 清空选中
      selectedRowKeys.value = []
      selectedRows.value = []
    }
  }).catch(() => {
    isLoading.value = false
  })
}
</script>

<style scoped>
.cover-img {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.cover-title {
  margin-left: 10px;
}
</style>