<template>
  <a-drawer
    :title="videoJson && videoJson.remark_name"
    width="1000"
    :visible="visible"
    :closable="false"
    :footer="false"
    @close="onClose"
  >
    <div class="tool-view">
      <!-- 弹簧 -->
      <div style="flex: 1;"></div>
      <!-- 导入其他短剧 -->
      <a-button style="margin-right: 12px;" @click="touchImport">
        <plus-outlined /> 导入其他短剧
      </a-button>
      <!-- 上传按钮 -->
      <Upload
        accept=".mp4"
        uploadSource="SeriesDrawerBatch"
        :multiple="true"
        :disabled="isLoading"
        :fileList="batchFileList"
        :uploadBucket="$pub.BUCKET_PRI()"
        :beforeUploadPro="beforeUploadBatchPro"
        style="display: inline-block;"
        @click="touchBatchUpload"
      >
        <template #up-title>批量上传</template>
      </Upload>
      <!-- 导出URL -->
      <a-button style="margin-left: 12px;" @click="touchExport">
        导出URL
      </a-button>
      <!-- 获取微小ID -->
      <a-button style="margin-left: 12px;" @click="touchGetID">
        获取微小ID
      </a-button>
      <!-- 同步至抖小 -->
      <a-button style="margin-left: 12px;" @click="touchSyncTt(undefined)">
        同步至抖小
      </a-button>
      <!-- 同步至快小 -->
      <!-- <a-button style="margin-left: 12px;" @click="touchSyncKs(undefined)">
        同步至快小
      </a-button> -->
      <!-- 快小发布 -->
      <!-- <a-button style="margin-left: 12px;" @click="touchPushKs(undefined)">
        快小发布
      </a-button> -->
    </div>
    <!-- 多选组件 -->
    <TableCheckbox
      sourceKey="id"
      :dataSource="dataSource"
      :selectedRowKeys="selectedRowKeys"
      :selectedRows="selectedRows"
      :pagination="pagination"
      :isLoading="isSyncAllLoading || isLoading"
      @select="selectAll"
    >
      <!-- 按钮文案 -->
      <template #checkbox>
        <a-button class="checkbox-btn" @click="touchSyncKsBatch">同步至快小</a-button>
      </template>
      <!-- 列表 -->
      <a-table
        class="table-view"
        :data-source="dataSource"
        :columns="columns"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="isLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :scroll="{ x: scrollX, y: 'max-content' }"
        @change="handleTableChange"
      >
        <!-- 自定义行内容 -->
        <template #bodyCell="{ column, record }">
          <!-- 文件名 -->
          <template v-if="column.key === 'name'">
            <div style="max-width: 100px;">
              <a @click="touchPreview(record)" v-if="record.url">{{ record.name }}</a>
              <span v-else>未上传</span>
            </div>
          </template>
          <!-- 微小ID -->
          <template v-if="column.key === 'wechat_media_id'">
            {{ record.wechat_media_id || '-' }}
          </template>
          <!-- 抖小ID -->
          <template v-if="column.key === 'douyin_media_id'">
            {{ record.douyin_media_id || '-' }}
          </template>
          <!-- 抖小状态 -->
          <template v-if="column.key === 'v_audit_status'">
            <span style="white-space: nowrap;">
              {{ (AuditStatusTt.find(item => item.value === record[column.key]) || {}).text || '-' }}
              <a-tooltip v-if="record.v_audit_msg">
                <template #title>{{ record.v_audit_msg }}</template>
                <info-circle-outlined />
              </a-tooltip>
            </span>
          </template>
          <!-- 快小状态 -->
          <template v-if="column.key === 'ks_audit_status'">
            <span style="white-space: nowrap;">
              {{ (AuditStatusTt.find(item => item.value === record[column.key]) || {}).text || '-' }}
              <a-tooltip v-if="record.ks_audit_msg">
                <template #title>{{ record.ks_audit_msg }}</template>
                <info-circle-outlined />
              </a-tooltip>
            </span>
          </template>
          <!-- 上传日期 -->
          <template v-if="column.key === 'upload_date'">
            {{ record.upload_date || '-' }}
          </template>
          <!-- 操作 -->
          <template v-if="column.key === 'operation'">
            <Upload
              v-if="visible"
              accept=".mp4"
              uploadSource="SeriesDrawer"
              :uploadBucket="$pub.BUCKET_PRI()"
              :beforeUploadPro="beforeUploadPro"
              :uploadResult="(fileJson, res) => { uploadResult(fileJson, res, record) }"
              style="display: inline-block;"
            >
              <template #up-slot>
                <a class="operation-item" v-if="record.url">更换</a>
                <a class="operation-item" v-else>上传</a>
              </template>
            </Upload>
            <a class="operation-item" @click="touchIntroduction(record)">剧集简介</a>
            <a-dropdown>
              <a class="ant-dropdown-link" @click.prevent>平台操作 <DownOutlined /></a>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="touchSyncTt(record)">同步至抖小</a-menu-item>
                  <a-menu-item @click="touchSyncKs(record)">同步至快小</a-menu-item>
                  <!-- <a-menu-item @click="touchPushKs(record)">快小发布</a-menu-item> -->
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </template>
      </a-table>
    </TableCheckbox>
    <!-- 预览 -->
    <PreviewVideoProModal ref="RefPreviewModal"></PreviewVideoProModal>
    <!-- 简介 -->
    <Introduction ref="RefIntroduction" @success="getVideoList"></Introduction>
    <!-- 导入 -->
    <ImportDrawer ref="RefImportDrawer" @success="getVideoList"></ImportDrawer>
    <!-- 加载 -->
    <Loading :loading="isSyncAllLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { Modal } from 'ant-design-vue'
import Pub from '@/utils/public'
import { onBeforeMount, reactive, ref, createVNode } from 'vue'
import { nextTick } from 'process'
import Upload from '@/components/Upload'
import Introduction from './Introduction'
import PreviewVideoProModal from '@/components/preview/PreviewVideoProModal'
import { message } from 'ant-design-vue'
import { AuditStatusTt } from '@/utils/constantList'
import { videoList, videoUpload, existVideo, exportWechatVideos, videoMedia, ttSyncVideo, ksSyncVideo, ksPushVideo } from '@/api/copyright'
import { uploadCustom } from '@/utils/upload'
import ImportDrawer from './ImportDrawer.vue'
import * as Ex from "@netjoy/excelex"
import Loading from '@/components/Loading'
import TableCheckbox from '@/components/TableCheckbox'

// 视频预览
let RefPreviewModal = ref(null)
// 剧集简介
let RefIntroduction = ref(null)
// 导入视频
let RefImportDrawer = ref(null)
// 视频信息
let videoJson = ref(null)
// 显示
let visible = ref(false)
// 滚动范围
let scrollX = ref(0)
// 加载
let isLoading = ref(false)
let isSyncAllLoading = ref(false)
// 批量上传列表
let batchFileList = ref([])
// 每轮批量上传ID
let batchUploadId = ref(null)
// 数据源
let dataSource = ref([])
// 选中
let selectedRowKeys = ref([])
let selectedRows = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 20,
  size: 'small',
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '集数',
    dataIndex: 'eq_number',
    key: 'eq_number',
    fixed: 'left',
    width: 100
  },
  {
    title: '文件名',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: 100
  },
  {
    title: '微小ID',
    dataIndex: 'wechat_media_id',
    key: 'wechat_media_id',
    width: 200
  },
  {
    title: '抖小视频ID',
    dataIndex: 'douyin_media_id',
    key: 'douyin_media_id',
    width: 200
  },
  {
    title: '抖小剧集ID',
    dataIndex: 'episode_id',
    key: 'episode_id',
    width: 200
  },
  {
    title: '抖小剧集状态',
    dataIndex: 'v_audit_status',
    key: 'v_audit_status',
    width: 200
  },
  {
    title: '快小视频ID',
    dataIndex: 'ks_media_id',
    key: 'ks_media_id',
    width: 200
  },
  {
    title: '快小剧集ID',
    dataIndex: 'ks_episode_id',
    key: 'ks_episode_id',
    width: 200
  },
  {
    title: '快小剧集状态',
    dataIndex: 'ks_audit_status',
    key: 'ks_audit_status',
    width: 200
  },
  {
    title: '上传日期',
    dataIndex: 'upload_date',
    key: 'upload_date',
    width: 200
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    fixed: 'right',
    width: 220
  }
])

onBeforeMount (() => {
  // 获取滚动宽度
  getScrollX()
})

// 显示
function showDrawer (data) {
  // 清空
  selectedRowKeys.value = []
  selectedRows.value = []
  dataSource.value = [{}]
  // 记录
  videoJson.value = data
  // 显示
  visible.value = true
  // 延迟处理
  nextTick(() => {
    // 获取列表
    getVideoList()
  })
}

// 滚动X
function getScrollX () {
  var x = 0
  columns.value.forEach(item => {
    x += item.width
  })
}

// 关闭
function onClose () {
  if (!isLoading.value && !isSyncAllLoading.value) {
    // 重置
    pagination.current = 1
    pagination.pageSize = 20
    visible.value = false
  }
}

// 导入其他短剧
function touchImport () {
  RefImportDrawer.value.showDrawer(videoJson.value)
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  // 获取列表
  getVideoList()
}

// 预览
function touchPreview (record) {
  RefPreviewModal.value.showModal(record.drama_id, record.eq_number)
}

// 剧集简介
function touchIntroduction (record) {
  RefIntroduction.value.showDrawer(record)
}

// 点击批量上传
function touchBatchUpload () {
  // 清空
  batchUploadId.value = ''
  batchFileList.value = []
}


// 全选
function selectAll (rowKeys, rows) {
  onSelectChange(rowKeys, rows)
}

// 多选处理
function onSelectChange (rowKeys, rows) {
  selectedRowKeys.value = rowKeys
  selectedRows.value = rows
}

// 同步至快小批量
function touchSyncKsBatch() {
  if (selectedRowKeys.value.length) {
    const params = {
      drama_id: videoJson.value.id,
      video_ids: selectedRowKeys.value
    }
    isSyncAllLoading.value = true
    ksSyncVideo(params).then(res => {
      const { code } = res
      if (code === 0) {
        message.success('同步成功')
        getVideoList()
      } else {
        message.error(res.message)
      }
      isSyncAllLoading.value = false
    }).catch(err => {
      message.error('同步失败')
      isSyncAllLoading.value = false
    })
  } else {
    message.error('未选中项目')
  }
}

// 批量上传
function beforeUploadBatchPro (file, fileList) {
  // 每轮上传，都在首次处理文件列表
  if (file.uploadId != batchUploadId.value) {
    // 进入加载
    isLoading.value = true
    // 记录
    batchUploadId.value = file.uploadId
    // 取出所有文件名
    var eqs = []
    const isOK = fileList.every(item => {
      // 分割
      const names = item.name.split('.')
      // 取出0位置
      const eq = names[0]
      // 文件命名有问题
      if (!Pub.REG_IS_POSITIVE_INTEGER(eq)) {
        Modal.error({
          title: `【${item.name}】未匹配到剧集`,
          content: '请核实文件命名方式后再上传',
          okText: '好 的'
        })
        return false
      }
      // 检查文件类型
      if (!file.type.includes('video')) {
        Modal.error({
          title: `【${item.name}】非视频类型`,
          content: '请核实文件类型后再上传',
          okText: '好 的'
        })
        return false
      }
      // 文件集数是否大于总集数
      if (Number(eq) > pagination.total) {
        Modal.error({
          title: `【${item.name}】大于剧集总集数`,
          content: '请核实文件命名方式后再上传',
          okText: '好 的'
        })
        return false
      }
      // 取出0位置
      eqs.push(eq)
      // 记录
      item.eq = eq
      // 返回
      return true
    })
    // 停止上传
    if (!isOK) {
      // 停止加载
      isLoading.value = false
      return false
    }
    // 转为数字数组
    eqs = eqs.map(Number)
    // 去重
    const repeatEqs = Array.from(new Set(eqs))
    // 是否数量一样
    if (repeatEqs.length !== eqs.length) {
      Modal.error({
        title: '部分剧集存在重复文件',
        content: '请核实文件命名方式后再上传',
        okText: '好 的'
      })
      // 停止加载
      isLoading.value = false
      return false
    }
    // 获取已存在剧集的集数
    existVideo({ drama_id: videoJson.value.id }).then(res => {
      const { code, data } = res
      if (code === 0) {
        // 便利
        const exeqs = []
        data.forEach(item => {
          // 是否存在
          if (eqs.includes(item)) { exeqs.push(item) }
        })
        // 检查是否有以存在的剧集需要覆盖
        if (exeqs.length) {
          Modal.confirm({
            title: `${exeqs.join('、')}集已存在`,
            content: createVNode('div', { style: 'color: red;' }, '新视频将覆盖原视频，你还要继续吗？'),
            cancelText: '取 消',
            okText: '继 续',
            onOk () {
              // 开始上传
              batchUpload(fileList)
            },
            onCancel () {
              // 停止加载
              isLoading.value = false
            }
          })
        } else {
          // 开始上传
          batchUpload(fileList)
        }
      } else {
        // 停止加载
        isLoading.value = false
        message.error(message)
      }
    }).catch(err => {
      // 停止加载
      isLoading.value = false
      message.error(err.message)
    })
  }
  // 禁止上传
  return false
}

// 同步至抖小
function touchSyncTt (record) {
  if (record && !record.url) {
    message.error('视频未上传，无法进行同步！')
    return
  }
  isSyncAllLoading.value = true
  ttSyncVideo({ drama_id: videoJson.value.id, video_id: record && record.id }).then(res => {
    const { code } = res
    if (code === 0) {
      message.success('同步成功')
      getVideoList()
    } else {
      message.error(res.message)
    }
    isSyncAllLoading.value = false
  }).catch(err => {
    message.error('同步失败')
    isSyncAllLoading.value = false
  })
}

// 同步至快小
function touchSyncKs (record) {
  if (record && !record.url) {
    message.error('视频未上传，无法进行同步！')
    return
  }
  isSyncAllLoading.value = true
  const params = {
    drama_id: videoJson.value.id,
    video_ids: !!record ? [record.id] : []
  }
  isSyncAllLoading.value = true
  ksSyncVideo(params).then(res => {
    const { code } = res
    if (code === 0) {
      message.success('同步成功')
      getVideoList()
    } else {
      message.error(res.message)
    }
    isSyncAllLoading.value = false
  }).catch(err => {
    message.error('同步失败')
    isSyncAllLoading.value = false
  })
}

// 快小发布
function touchPushKs (record) {
  if (record && !record.url) {
    message.error('视频未上传，无法进行同步！')
    return
  }
  isSyncAllLoading.value = true
  ksPushVideo({ drama_id: videoJson.value.id, video_id: record && record.id }).then(res => {
    const { code } = res
    if (code === 0) {
      message.success('同步成功')
      getVideoList()
    } else {
      message.error(res.message)
    }
    isSyncAllLoading.value = false
  }).catch(err => {
    message.error('同步失败')
    isSyncAllLoading.value = false
  })
}

// // 开始上传
// function batchUpload (fileList) {
//   // 总数量
//   const total = fileList.length
//   // 成功集数
//   var seqs = []
//   // 失败集数
//   var feqs = []
//   fileList.forEach(file => {
//     uploadCustom({ file, eq: file.eq }, (fileJson, res) => {
//       // 上传结果判断
//       if (fileJson.status === 'done') {
//         // 上传成功
//         const params = {
//           drama_id: videoJson.value.id,
//           eq_number: fileJson.eq,
//           name: fileJson.name,
//           url: fileJson.url,
//           key: fileJson.key,
//           duration: fileJson.duration,
//           size: fileJson.size,
//           preview_url: fileJson.url,
//           size: fileJson.size
//         }
//         videoUpload(params).then(res => {
//           const { code } = res
//           if (code === 0) {
//             // 记录成功
//             seqs.push(fileJson.eq)
//             // 刷新结果
//             reloadBatchUploadResult(seqs, feqs, total)
//           } else {
//             // 记录失败
//             feqs.push(fileJson.eq)
//             // 刷新结果
//             reloadBatchUploadResult(seqs, feqs, total)
//           }
//         }).catch(() => {
//           // 记录失败
//           feqs.push(fileJson.eq)
//           // 刷新结果
//           reloadBatchUploadResult(seqs, feqs, total)
//         })
//       } else if (fileJson.status === 'error') {
//           // 记录失败
//           feqs.push(fileJson.eq)
//           // 刷新结果
//           reloadBatchUploadResult(seqs, feqs, total)
//       }
//     }, Pub.BUCKET_PRI(), 'SeriesDrawerBatch')
//   })
// }

// 开始上传
function batchUpload (fileList) {
  // 总数量
  const total = fileList.length
  // 成功集数
  var seqs = []
  // 失败集数
  var feqs = []
  // 递归上传
  uploadFile(0, fileList, seqs, feqs, total)
}

// 递归上传
function uploadFile (index, fileList, seqs, feqs, total) {
  // 需要上传的问题
  const file = fileList[index]
  // 提示上传结果
  message.info(`正在上传【${file.name}】`)
  // 开始上传
  uploadCustom({ file, eq: file.eq }, (fileJson, res) => {
    // 上传结果判断
    if (fileJson.status === 'done') {
      // 上传成功
      const params = {
        drama_id: videoJson.value.id,
        eq_number: fileJson.eq,
        name: fileJson.name,
        url: fileJson.url,
        key: fileJson.key,
        duration: fileJson.duration,
        size: fileJson.size,
        preview_url: fileJson.url,
        size: fileJson.size
      }
      videoUpload(params).then(res => {
        const { code } = res
        if (code === 0) {
          // 提示上传结果
          message.success(`【${file.name}】上传成功，当前进度【${index + 1} / ${total}】`)
          // 记录成功
          seqs.push(fileJson.eq)
          // 刷新结果
          reloadBatchUploadResult(index, fileList, seqs, feqs, total)
        } else {
          // 提示上传结果
          message.error(`【${file.name}】上传失败，当前进度【${index + 1} / ${total}】`)
          // 记录失败
          feqs.push(fileJson.eq)
          // 刷新结果
          reloadBatchUploadResult(index, fileList, seqs, feqs, total)
        }
      }).catch(() => {
        // 提示上传结果
        message.error(`【${file.name}】上传失败，当前进度【${index + 1} / ${total}】`)
        // 记录失败
        feqs.push(fileJson.eq)
        // 刷新结果
        reloadBatchUploadResult(index, fileList, seqs, feqs, total)
      })
    } else if (fileJson.status === 'error') {
        // 提示上传结果
        message.error(`【${file.name}】上传失败，当前进度【${index + 1} / ${total}】`)
        // 记录失败
        feqs.push(fileJson.eq)
        // 刷新结果
        reloadBatchUploadResult(index, fileList, seqs, feqs, total)
    }
  }, Pub.BUCKET_PRI(), 'SeriesDrawerBatch')
}

// 刷新上传结果
function reloadBatchUploadResult (index, fileList, seqs, feqs, total) {
  // 上传完了，进行结算
  if (index === (total - 1)) {
    // 停止加载
    isLoading.value = false
    // 检查是否有失败的
    if (feqs.length) {
      // 有失败的
      Modal.error({
        title: '部分集数上传失败，请重新上传！',
        content: `${feqs.join('、')}`,
        okText: '好 的'
      })
    } else {
      // 没有失败的
      Modal.success({
        title: '上传成功！',
        okText: '好 的'
      })
      // 更新列表
      getVideoList()
    }
  } else {
    // 继续上传
    uploadFile(index + 1, fileList, seqs, feqs, total)
  }
}

// 准备上传
function beforeUploadPro (file) {
  if (!file.type.includes('video')) {
    message.error('请上传视频类型的文件')
    return false
  }
  isLoading.value = true
  return true
}

// 上传结果
function uploadResult (fileJson, res, record) {
  // 上传结果判断
  if (fileJson.status === 'done') {
    isLoading.value = false
    const params = {
      drama_id: record.drama_id,
      eq_number: record.eq_number,
      name: fileJson.name,
      url: fileJson.url,
      key: fileJson.key,
      duration: fileJson.duration,
      size: fileJson.size,
      preview_url: fileJson.url,
      size: res.res.size
    }
    videoUpload(params).then(res => {
      const { code } = res
      if (code === 0) {
        // 成功
        if (record.id) {
          message.success('更新成功')
        } else {
          message.success('上传成功')
        }
        // 刷新列表
        getVideoList()
      } else {
        // 失败
        message.error(res.message)
      }
    }).catch(() => {
      // 失败
      if (record.id) {
        message.error('更新失败')
      } else {
        message.error('上传失败')
      }
    })
  } else if (fileJson.status === 'error') {
    isLoading.value = false
    if (record.id) {
      message.error('更新失败')
    } else {
      message.error('上传失败')
    }
  } else {
    // console.log('正在上传');
  }
}

// 同步媒资
function ttSyncVideoMedia () {
  isLoading.value = true
  const params = {
    drama_id: videoJson.value.id
  }
  videoMedia(params).then(res => {
    const { code, data } = res
    if (code === 0) {
      message.success('获取成功')
      getVideoList()
    } else {
      isLoading.value = false
      message.error(res.message || '获取失败')
    }
  }).catch(() => {
    message.error('获取失败')
    isLoading.value = false
  })
}

// 获取列表
function getVideoList () {
  isLoading.value = true
  const params = {
    drama_id: videoJson.value.id,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  videoList(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      // 清空
      selectedRowKeys.value = []
      selectedRows.value = []
      // 重置列表数据
      dataSource.value = data.data
      pagination.total = data.total
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 获取微小ID
function touchGetID () {
  // 同步媒资
  ttSyncVideoMedia()
}

// 导出URL
function touchExport () {
  isLoading.value = true
  const params = {
    drama_id: videoJson.value.id
  }
  exportWechatVideos(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      const list = data
      if (list.length) {
        const columns = [
          {
            name: '',
            field: 'cover_url'
          },
          {
            name: '',
            field: 'original_url'
          },
          {
            name: '',
            field: 'file_name'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          return item
        }, null, 'csv')
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error(res.message || '导出失败')
    }
  }).catch((err) => {
    message.error(err.message)
    isLoading.value = false
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  display: flex;
}
.table-view {
  margin-top: 20px;
}

</style>