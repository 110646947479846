<template>
  <a-drawer
    :title="`${data.remark_name}`"
    width="800"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- 功能条 -->
    <div class="tool-view">
      <span class="tool-title">注意：抖小授权是覆盖操作，每次授权时请选择所有需要授权的小程序。</span>
    </div>
    <!-- 多选组件 -->
    <TableCheckbox
      sourceKey="id"
      :dataSource="dataSource"
      :selectedRowKeys="selectedRowKeys"
      :selectedRows="selectedRows"
      :pagination="null"
      :isLoading="isLoading"
      @select="selectAll"
    >
      <!-- 按钮文案 -->
      <template #checkbox>
        <a-button class="checkbox-btn" @click="touchAuth">授权</a-button>
        <a-button class="checkbox-btn" @click="touchBind">绑定页面</a-button>
      </template>
      <!-- 列表 -->
      <a-table
        class="table-view"
        :data-source="dataSource"
        :columns="columns"
        :row-key="record => record.id"
        :pagination="pagination"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
      >
        <!-- 自定义行内容 -->
        <template #bodyCell="{ column, record }">
          <!-- 授权 -->
          <template v-if="column.key === 'is_auth'">
            {{ record[column.key] === 0 ? '未授权' : '已授权' }}
          </template>
          <!-- 绑定 -->
          <template v-if="column.key === 'is_bind'">
            {{ record[column.key] === 0 ? '未绑定' : '已绑定' }}
          </template>
        </template>
      </a-table>
    </TableCheckbox>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message, Modal } from 'ant-design-vue'
import dayjs from 'dayjs'
import { dyDramaList, dyAuthDrama, dyBindPage } from '@/api/copyright'
import Loading from '@/components/Loading'
import TableCheckbox from '@/components/TableCheckbox'
import Pub from '@/utils/public'

// emit
const emit = defineEmits(['success'])
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 当前数据
let data = ref({})
// 数据源
let dataSource = ref([])
// 选中
let selectedRowKeys = ref([])
let selectedRows = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 100000,
  showSizeChanger: false
})
// 列数据源
let columns = ref([
  {
    title: '抖小',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '授权小程序',
    dataIndex: 'is_auth',
    key: 'is_auth'
  },
  {
    title: '绑定小程序页面',
    dataIndex: 'is_bind',
    key: 'is_bind'
  }
])

// 显示
function showDrawer (record) {
  // 清空
  selectedRowKeys.value = []
  selectedRows.value = []
  dataSource.value = [{}]
  // 记录
  data.value = record
  // 显示
  visible.value = true
  // 获取数据详情列表
  getList()
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    pagination.current = 1
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 切换日期
function onDateChange () {
  // 设置分页
  pagination.current = 1
  // 数据
  getList()
}

// 获取数据详情列表
function getList () {
  // 获取数据
  isLoading.value = true
  const params = {
    drama_id: data.value.id,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  dyDramaList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 清空
      selectedRowKeys.value = []
      selectedRows.value = []
      // 重置列表数据
      dataSource.value = data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 全选
function selectAll (rowKeys, rows) {
  onSelectChange(rowKeys, rows)
}

// 多选处理
function onSelectChange (rowKeys, rows) {
  selectedRowKeys.value = rowKeys
  selectedRows.value = rows
}

// 授权
function touchAuth() {
  if (selectedRowKeys.value.length) {
    isLoading.value = true
    const params = {
      drama_id: data.value.id,
      project_ids: selectedRowKeys.value
    }
    dyAuthDrama(params).then(res => {
      isLoading.value = false
      const { code, data } = res
      if (code === 0 && (data ? data.err_no === 0 : true)) {
        message.success('授权成功')
        getList()
      } else {
        Modal.error({
          title: '操作错误',
          content: (data && data.err_msg) || res.message
        })
      }
    }).catch(() => {
      isLoading.value = false
      Modal.error({
        title: '操作错误',
        content: '授权失败'
      })
    })
  } else {
    message.error('未选中项目')
  }
}

// 绑定页面
function touchBind() {
  if (selectedRowKeys.value.length) {
    isLoading.value = true
    const params = {
      drama_id: data.value.id,
      project_ids: selectedRowKeys.value
    }
    dyBindPage(params).then(res => {
      isLoading.value = false
      const { code, data } = res
      if (code === 0 && (data ? data.err_no === 0 : true)) {
        message.success('绑定成功')
        getList()
      } else {
        Modal.error({
          title: '操作错误',
          content: (data && data.err_msg) || res.message
        })
      }
    }).catch(() => {
      isLoading.value = false
      Modal.error({
        title: '操作错误',
        content: '绑定失败'
      })
    })
  } else {
    message.error('未选中项目')
  }
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
}
.table-view {
  margin-top: 30px;
}
.tool-title {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
}

.footer-view {
  display: flex;
  align-items: center;
}
.hint-info {
  color: #A7A7A7;
}
.hint-desc {
  color: #323232;
}
.checkbox-btn {
  font-size: 12px;
  margin-right: 8px;
}
</style>